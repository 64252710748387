<template>
  <div>
    <Sidebar />
    <StoreImage :store_detail="detailStore" />
    <div class="ps-2">
      <Username />
    </div>
    <div style="margin-bottom: 7em">
      <HeaderNavigasi :link="links" />

      <div class="class-category">
        <div class="d-flex justify-center px-2 pb-4">
          <span class="text-h6 text-category-style">{{
            $t("message.label-category")
          }}</span>
        </div>
        <v-container class="px-5">
          <v-row v-if="isLoading">
            <v-col v-for="i in 10" :key="i" cols="4" md="2" sm="4" style="padding-right: 2px;">
              <Skeleton />
            </v-col>
          </v-row>
          <v-row v-else class="text-center">
            <v-col
              v-for="(category, i) in listCategory"
              :key="i"
              cols="4"
              md="2"
              sm="4"
              style="padding:3px"
            >
              <v-card
                height="130"
                class="pa-2"
                flat
                @click.prevent="detailCategory(category.slug)"
              >
                <v-img height="55" contain :src="category.image_url" />
                <v-card-text class="p-0" style="padding: 0px;">
                  <div class="mt-2 style-title-shop_category">
                    <span class="mt-2 black--text" style="font-size: 14px">{{category.category_name}}</span>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>

      <div>
        <CategoryFooter />
      </div>
    </div>
    <Cashier />
  </div>
</template>

<script>
import CategoryFooter from "../../components/CategoryFooter.vue";
import User from "../../components/User.vue";
import HeaderNavigasi from "../../components/HeaderNavigasi.vue";
import StoreImage from "../../components/StoreImage.vue";
import Sidebar from "../../components/Sidebar.vue";
import SkeletonShopCategory from "../../components/skeleton/SkeletonShopCategory.vue";
import Cashier from "../../components/Cashier.vue";
import { mdiMagnify, mdiCart, mdiHeartOutline, mdiStore } from "@mdi/js";
export default {
  name: "ShopCategory",
  data() {
    return {
      idStore: "",
      isLoading: true,
      notFound: require("../../assets/image-not-found.png"),
    };
  },
  components: {
    Sidebar,
    StoreImage,
    HeaderNavigasi,
    Username: User,
    CategoryFooter,
    Skeleton: SkeletonShopCategory,
    Cashier,
  },
  methods: {
    dispatchStore() {
      this.idStore = this.$router.history.current.params.id;
      this.$store.dispatch("shop_module/fetchStore", { id: this.idStore });
    },
    async listCategoryByStore() {
      this.isLoading = true;
      let res = await this.$store.dispatch(
        "shop_module/fetchCategoryItemShop",
        { id: this.idStore }
      );
      this.isLoading = false;
      return res;
    },
    detailCategory(slug) {
      let id_store = this.$router.history.current.params.id;
      // this.$router.push(`/shop/category/items/${slug}/${id_store}`);
      this.$router.push(`/store/${id_store}/${slug}`);

    },
  },
  computed: {
    detailStore() {
      return this.$store.state.shop_module.store;
    },
    links() {

      return [
        {
          text: this.$t("message.route-search"),
          route: `/store/${this.idStore}/category`,
          icon: mdiMagnify,
        },
        {
          text: this.$t("message.route-favorite"),
          route: `/store/${this.idStore}/wishlist`,
          icon: mdiHeartOutline,
        },
        {
          text: this.$t("message.route-history"),
          route: `/store/${this.idStore}/purchase-history`,
          icon: mdiCart,
        },
        {
          text: this.$t("message.route-store"),
          route: "/store",
          icon: mdiStore,
        },
      ];
    },
    listCategory() {
      return this.$store.state.shop_module.categoryItemShop;
    },
  },
  created() {
    this.dispatchStore();
    this.listCategoryByStore();
  },
};
</script>

<style>
.text-category-style {
  color: #212121;
}
.style-title-shop_category {
  text-align: center;
  line-height: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  
}
.class-category {
  background-color: #e0e0e0;
  width: 100%;
  height: auto;
  padding: 20px 0px 20px 0px;
  margin: 0px;
}
.card-category {
  width: 135px;
  height: 12vh;
  justify-content: center;
  margin: 3px;
}
.card-item {
  justify-content: center;
}
</style>
